<template>
	<div>
		<div class="page-title">
			<span style="font-size: 20px;">群众举报</span>
		</div>
		<div class="bg-white p-2 m-2">
			<div class="d-flex flex-wrap align-items-center">
				<div>提交时间：</div>
				<el-date-picker style="width: 400px;" class="m-1" size="small" @change="changeTime" v-model="time"
					type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期"
					end-placeholder="结束日期">
				</el-date-picker>
				<div class="d-flex align-items-center m-1">
					<el-input style="width: 200px" size="small" v-model="search.keyword" placeholder="请输入搜索内容" clearable
						@clear="toSearch" @keyup.enter.native="toSearch">
					</el-input>
					<el-button class="ml-1" @click="toSearch" type="primary" size="mini" icon="el-icon-search">
					</el-button>
				</div>
				<div class="d-flex align-items-center m-2">
					<div class="label ">是否匿名：</div>
					<el-select size="small" style="width: 120px" v-model="search.is_anonymity" @change="toSearch"
						placeholder="是否匿名">
						<el-option label="全部" :value="0"></el-option>
						<el-option label="否" value="10"></el-option>
						<el-option label="是" value="20"></el-option>
					</el-select>
				</div>
				<div class="d-flex align-items-center m-2">
					<div class="label ">是否已分配：</div>
					<el-select size="small" style="width: 120px" v-model="search.is_allocation" @change="toSearch"
						placeholder="是否分配">
						<el-option label="全部" :value="0"></el-option>
						<el-option label="否" value="10"></el-option>
						<el-option label="是" value="20"></el-option>
					</el-select>
				</div>
				<div class="d-flex align-items-center m-2">
					<div class="label ">状态：</div>
					<el-select size="small" style="width: 120px" v-model="search.status" @change="toSearch"
						placeholder="状态">
						<el-option label="全部" :value="0"></el-option>
						<el-option label="未处理" value="10"></el-option>
						<el-option label="查实" value="20"></el-option>
						<el-option label="整改" value="30"></el-option>
						<el-option label="反馈" value="40"></el-option>
					</el-select>
				</div>
				<el-button @click="handleExport" class="ml-2" type="primary" size="mini">导出</el-button>
			</div>
			<el-table border class="mt-3" :data="lists.data" style="width: 100%" :default-expand-all="true">
				<el-table-column type="expand">
					<template slot-scope="scope">
						<div class="d-flex" style="font-weight: 700;">
							地区：{{scope.row.first_unit_name}} - {{scope.row.second_unit_name}}
						</div>
						<div class="d-flex" style="font-weight: 700;">
							位置定位：{{scope.row.latitude}},{{scope.row.longitude}}
							<el-button size="mini" @click="showMap(scope.row)">展开地图</el-button>
						</div>
						<div class="d-flex" style="font-weight: 700;">
							<div>详细地址：{{scope.row.address}}</div>
						</div>
						<div class="d-flex" style="font-weight: 700;">
							举报内容：{{scope.row.content}}
						</div>
						<template v-if="scope.row.image.length > 0 || scope.row.video != ''">
							<div class="d-flex" style="font-weight: 700;">
								<div v-if="scope.row.image.length > 0" style="line-height: 60px;">举报图片：</div>
								<viewer v-if="scope.row.image.length > 0" style="padding: 10px;">
									<img v-for="image in scope.row.image" style="width: 45px;height: 45px;margin:0 5px;"
										:src="image.image">
								</viewer>
							</div>
						</template>
						<div v-if="scope.row.video != ''" class="d-flex" style="font-weight: 700;">
							举报视频：<span><a target="_blank" :href="scope.row.video">{{scope.row.video}}</a></span>
						</div>
					</template>
				</el-table-column>
				<el-table-column align="center" label="场所名称">
					<template slot-scope="scope">
						<viewer>
							<span style="font-weight: 800;">{{scope.row.name}}</span>
						</viewer>
					</template>
				</el-table-column>
				<!-- <el-table-column align="center" width="120" prop="report_id" label="举报ID"></el-table-column> -->
				<el-table-column align="center" label="用户头像" width="110">
					<template slot-scope="scope">
						<viewer>
							<img style="width: 45px;height: 45px;" :src="scope.row.user.avatarUrl">
						</viewer>
					</template>
				</el-table-column>
				<el-table-column align="center" label="昵称">
					<template slot-scope="scope">
						<viewer>
							<span style="font-weight: 800;">{{scope.row.user.nickName}}</span>
						</viewer>
					</template>
				</el-table-column>
				<el-table-column align="center" label="姓名" width="110">
					<template slot-scope="scope">
						<template v-if="scope.row.is_anonymity == 10">
							<span style="font-weight: 800;">{{scope.row.user.real_name}}</span>
						</template>
						<template v-else>
							<span style="font-weight: 800;">匿名</span>
						</template>
					</template>
				</el-table-column>
				<el-table-column align="center" label="手机号" width="120">
					<template slot-scope="scope">
						<template v-if="scope.row.is_anonymity == 10">
							<span style="font-weight: 800;">{{scope.row.user.mobile}}</span>
						</template>
						<template v-else>
							<span style="font-weight: 800;">{{scope.row.mobile}}</span>
						</template>
					</template>
				</el-table-column>
				<el-table-column align="center" label="是否分配">
					<template slot-scope="scope">
						<el-tag type="danger" v-if="scope.row.is_allocation == 10">未分配</el-tag>
						<el-tag v-else>已分配</el-tag>
					</template>
				</el-table-column>
				<el-table-column align="center" label="分配单位">
					<template slot-scope="scope">
						<template v-if="scope.row.is_allocation == 20">
							<span style="font-weight: 800;">{{scope.row.first_unit_name}}</span>
							<template v-if="scope.row.second_unit_name != ''">
								<span style="font-weight: 800;">- {{scope.row.second_unit_name}}</span>
							</template>
							<template v-if="scope.row.third_unit_name != ''">
								<span style="font-weight: 800;">- {{scope.row.third_unit_name}}</span>
							</template>
						</template>
						<el-tag type="danger" v-else="scope.row.is_allocation == 10">未分配</el-tag>
					</template>
				</el-table-column>
				<el-table-column align="center" label="分配单位用户">
					<template slot-scope="scope">
						<template v-if="scope.row.is_allocation_user == 20">
							{{scope.row.unit_user.real_name}} {{scope.row.unit_user.mobile}}
							<el-button type="text" @click="allocationUser(scope.row)" size="mini">修改</el-button>
						</template>
						<el-button type="text" v-else @click="allocationUser(scope.row)" size="mini">分配用户</el-button>
					</template>
				</el-table-column>
				<el-table-column align="center" label="状态">
					<template slot-scope="scope">
						<template v-if="scope.row.status == 10">
							<el-tag type="danger">未处理</el-tag>
						</template>
						<template v-else-if="scope.row.status == 20">
							<el-tag type="info">查实</el-tag>
						</template>
						<template v-else-if="scope.row.status == 30">
							<el-tag type="warning">整改</el-tag>
						</template>
						<template v-else-if="scope.row.status == 40">
							<el-tag type="success">反馈</el-tag>
						</template>
					</template>
				</el-table-column>
				<!-- <el-table-column align="center" label="是否匿名">
					<template slot-scope="scope">
						<template v-if="scope.row.is_anonymity == 10">
							<span style="font-weight: 800;">否</span>
						</template>
						<template v-else>
							<span style="font-weight: 800;">是</span>
						</template>
					</template>
				</el-table-column> -->
				<el-table-column align="center" width="160" label="日期">
					<template slot-scope="scope">
						<span style="font-weight: 800;">{{scope.row.date}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" label="操作">
					<template slot-scope="scope">
						<el-button type="text" @click="openDialog(scope.row)" size="mini" class="table-btn">操作处理
						</el-button>
						<el-button type="text" size="mini" @click="deleteItem(scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div style="height: 30px;" class="mt-2 px-2">
				<el-pagination :page-size="lists.per_page" :current-page.sync="search.page"
					style="display: inline-block;float: right;" background @current-change="pageChange"
					layout="prev, pager, next" :total="lists.total">
				</el-pagination>
			</div>
			<el-dialog title="操作处理" :visible.sync="dialogVisible" top="3vh" max-width="650px" height="60%">
				<!-- 添加/修改规则 -->
				<el-form :rules="formRules" ref="form" :model="form" label-width="100px">
					<el-form-item label="单位">
						<el-select @change="handleFirstChange" v-model="form.first_id" placeholder="请选择支队">
							<el-option label="请选择" :key="0" :value="0">
							</el-option>
							<el-option v-for="item in firstList" :key="item.unit_id" :label="item.unit_name"
								:value="item.unit_id">
							</el-option>
						</el-select>
						<el-select @change="handleSecondChange" v-model="form.second_id" placeholder="请选择大队">
							<el-option label="请选择" :key="0" :value="0">
							</el-option>
							<el-option v-for="item in secondList" :label="item.unit_name" :key="item.unit_id"
								:value="item.unit_id">
							</el-option>
						</el-select>
						<el-select @change="handleThirdChange" v-model="form.third_id" placeholder="请请选择街道">
							<el-option label="请选择" :key="0" :value="0">
							</el-option>
							<el-option v-for="item in thirdList" :label="item.unit_name" :key="item.unit_id"
								:value="item.unit_id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="状态" prop="status">
						<el-radio v-model="form.status" :value="10" :label="10">
							未处理
						</el-radio>
						<el-radio v-model="form.status" :value="20" :label="20">
							查实
						</el-radio>
						<el-radio v-model="form.status" :value="30" :label="30">
							整改
						</el-radio>
						<el-radio v-model="form.status" :value="40" :label="40">
							反馈
						</el-radio>
					</el-form-item>
					<el-form-item label="查实内容" prop="verify">
						<app-rich-text :key="verifyTimer" v-model="form.verify"></app-rich-text>
					</el-form-item>
					<el-form-item label="整改内容" prop="change">
						<app-rich-text :key="changeTimer" v-model="form.change"></app-rich-text>
					</el-form-item>
					<el-form-item label="反馈内容" prop="feedback">
						<app-rich-text :key="feedbackTimer" v-model="form.feedback"></app-rich-text>
					</el-form-item>
				</el-form>
				<span slot="footer" class="dialog-footer">
					<el-button type="primary" @click="submitForm('form')">确 定</el-button>
					<el-button @click="dialogVisible = false">取 消</el-button>
				</span>
			</el-dialog>
			<el-dialog title="分配单位用户" :visible.sync="allocationUserVisible">
				<!-- 添加/修改规则 -->
				<el-form :rules="allocationUserFormRules" ref="AllocationUser" :model="allocationUserForm"
					label-width="100px">
					<el-form-item label="选择用户" prop="real_name">
						<el-input v-model="allocationUserForm.real_name" placeholder="请选择用户">
							<template slot="append">
								<el-button class="button-item" @click.native="unitUserListVisible = true" size="small">
									选择用户</el-button>
							</template>
						</el-input>
						<div class="d-flex flex-wrap">
							<div v-if="allocationUserForm.unit_user_id > 0" @mouseenter="enter()" @mouseleave="leave"
								style="margin-right: 20px;position: relative;cursor: pointer;">
								<app-image mode="aspectFill" width="50px" height='50px' :src="unitUser.user.avatarUrl">
								</app-image>
								<el-button class="del-btn" v-if="user_status == 1" size="mini" type="danger"
									icon="el-icon-close" circle @click="unitUserDestroy()"></el-button>
							</div>
						</div>
					</el-form-item>
				</el-form>
				<span slot="footer" class="dialog-footer">
					<el-button type="primary" @click="submitAllocationUserForm('AllocationUser')">确 定</el-button>
					<el-button @click="allocationUserVisible = false">取 消</el-button>
				</span>
			</el-dialog>
			<el-dialog title="选择单位用户" :visible.sync="unitUserListVisible" append-to-body>
				<el-form @submit.native.prevent>
					<el-form-item>
						<el-input size="small" v-model="unitUserSearch.keyword" autocomplete="off" placeholder="昵称姓名手机号"
							style="width: 40%"></el-input>
						<el-button size="small" @click="getUnitUserList()">查找用户</el-button>
					</el-form-item>
				</el-form>
				<el-table :data="unitUserList.data" style="width: 100%">
					<el-table-column label="头像">
						<template slot-scope="scope">
							<app-image mode="aspectFill" style="float: left;margin-right: 8px"
								:src="scope.row.user.avatarUrl"></app-image>
							<div>{{scope.row.user.nickName}}</div>
							<img class="platform-img" src="statics/img/area/wx.png" alt="">
						</template>
					</el-table-column>
					<el-table-column align="center" label="姓名">
						<template slot-scope="scope">
							<span style="font-weight: 800;">{{scope.row.real_name}}</span>
						</template>
					</el-table-column>
					<el-table-column align="center" label="手机号">
						<template slot-scope="scope">
							<span style="font-weight: 800;">{{scope.row.mobile}}</span>
						</template>
					</el-table-column>
					<el-table-column align="center" label="单位">
						<template slot-scope="scope">
							<span style="font-weight: 800;">{{scope.row.first_unit_name}}</span>
							<template v-if="scope.row.second_unit_name != ''">
								<span style="font-weight: 800;">- {{scope.row.second_unit_name}}</span>
							</template>
							<template v-if="scope.row.third_unit_name != ''">
								<span style="font-weight: 800;">- {{scope.row.third_unit_name}}</span>
							</template>
						</template>
					</el-table-column>
					<el-table-column label="操作">
						<template slot-scope="scope">
							<el-button v-if="form.user_id != scope.row.user_id" plain size="mini" type="primary"
								@click="unitUserAdd(scope.row)">选择
							</el-button>
							<el-button v-else plain size="mini" type="primary" disabled>已选择</el-button>
						</template>
					</el-table-column>
				</el-table>
				<div slot="footer" class="dialog-footer" style="height: 30px;">
					<el-pagination :page-size="unitUserList.per_page" :current-page.sync="unitUserSearch.page"
						style="display: inline-block;float: right;" background @current-change="unitUserPageChange"
						layout="prev, pager, next" :total="unitUserList.total">
					</el-pagination>
				</div>
			</el-dialog>
			<app-map ref="appMap" :lat="latitude" :long="longitude" :address="address"></app-map>
		</div>
	</div>
</template>

<script>
	import appRichText from '@/components/app-rich-text.vue';
	import appMap from '@/components/app-map.vue';
	export default {
		inject: ['layout'],
		components: {
			appRichText,
			appMap,
		},
		data() {
			return {
				lists: [],
				unitUserList: [],
				latitude: "",
				longitude: "",
				address: "",
				dialogVisible: false,
				allocationUserVisible: false,
				unitUserListVisible: false,
				unitUserSearch: {
					page: 1,
					s: 'store/unit_user/index',
					keyword: '',
				},
				unitList: [],
				verifyTimer: new Date().getTime(),
				changeTimer: new Date().getTime(),
				feedbackTimer: new Date().getTime(),
				firstList: [],
				secondList: [],
				thirdList: [],
				form: {
					report_id: 0,
					status: 10,
					verify: '',
					change: '',
					feedback: '',
					first_id: '',
					second_id: '',
					third_id: 0,
					first_unit_name: '',
					second_unit_name: '',
					third_unit_name: '',
				},
				formRules: {},
				allocationUserForm: {
					report_id: 0,
					unit_user_id: 0,
					real_name: '',
				},
				allocationUserFormRules: {
					real_name: [{
						required: true,
						message: '请选择单位用户',
						trigger: 'change'
					}],
				},
				time: [],
				search: {
					s: 'store/report/index',
					keyword: '',
					status: 0,
					is_allocation: 0,
					is_anonymity: 0,
					page: 1,
					start_time: null,
					end_time: null,
				},
				unitUser: {},
				user_status: 0,
			}
		},
		created() {
			this.getList();
			this.getUnitList();
		},
		methods: {
			handleExport() {
				let params = {
					s: 'store/report/export',
					keyword: this.search.keyword,
					status: this.search.status,
					is_allocation: this.search.is_allocation,
					is_anonymity: this.search.is_anonymity,
					start_time: this.search.start_time,
					end_time: this.search.end_time,
				};
				this.axios({
					token: true,
					params: params,
					method: 'get',
				}).then(res => {
					if (res.data.code == 1) {
						window.location.href = this.$conf.base_url + 'report_list.xls'
					}
				}).catch(err => {})
			},
			changeTime() {
				if (this.time) {
					this.search.start_time = this.time[0];
					this.search.end_time = this.time[1];
				} else {
					this.search.start_time = null;
					this.search.end_time = null;
				}
				this.toSearch();
			},
			enter() {
				this.user_status = 1;
			},
			leave() {
				this.user_status = 0;
			},
			unitUserPageChange() {
				this.getUnitUserList()
			},
			unitUserDestroy() {
				this.unitUser = {};
				this.allocationUserForm.unit_user_id = 0;
				this.allocationUserForm.real_name = "";
			},
			unitUserAdd(row) {
				this.allocationUserForm.unit_user_id = row.unit_user_id;
				this.unitUser = row;
				this.allocationUserForm.real_name = row.real_name;
				this.unitUserListVisible = false;
			},
			getUnitUserList() {
				this.layout.showLoading()
				this.axios({
					token: true,
					params: this.unitUserSearch,
					method: 'get',
				}).then(res => {
					this.layout.hideLoading()
					this.unitUserList = res.data.data
				}).catch(err => {
					this.layout.hideLoading()
				})
			},

			showMap(item) {
				this.latitude = item.latitude;
				this.longitude = item.longitude;
				this.address = item.address;
				this.$refs.appMap.dialogVisible = !this.$refs.appMap.dialogVisible
			},
			handleFirstChange(first_id) {
				this.form.second_id = '';
				this.form.third_id = 0;
				this.form.second_unit_name = '';
				this.form.third_unit_name = '';
				for (let i = 0; i < this.firstList.length; i++) {
					if (this.firstList[i]['unit_id'] == first_id) {
						this.secondList = this.firstList[i].child;
						this.form.first_unit_name = this.firstList[i].unit_name;
					}
				}
			},
			handleSecondChange(second_id) {
				this.form.third_id = 0;
				this.form.third_unit_name = '';
				for (let i = 0; i < this.secondList.length; i++) {
					if (this.secondList[i]['unit_id'] == second_id) {
						this.thirdList = this.secondList[i].child;
						this.form.second_unit_name = this.secondList[i].unit_name;
					}
				}
			},
			handleThirdChange(third_id) {
				for (let i = 0; i < this.thirdList.length; i++) {
					if (this.thirdList[i]['unit_id'] == third_id) {
						this.form.third_unit_name = this.thirdList[i].unit_name;
					}
				}
			},
			// 打开弹出层
			openDialog(item) {
				this.form.report_id = item.report_id
				this.form.status = item.status
				this.form.verify = item.verify
				this.form.change = item.change
				this.form.feedback = item.feedback

				this.form.first_id = item.first_id
				this.form.second_id = item.second_id
				this.form.third_id = item.third_id
				this.form.first_unit_name = item.first_unit_name
				this.form.second_unit_name = item.second_unit_name
				this.form.third_unit_name = item.third_unit_name
				this.verifyTimer = new Date().getTime()
				this.changeTimer = new Date().getTime()
				this.feedbackTimer = new Date().getTime()

				if (this.form.first_id > 0) {
					for (let i = 0; i < this.firstList.length; i++) {
						if (this.firstList[i]['unit_id'] == this.form.first_id) {
							this.secondList = this.firstList[i].child;
						}
					}
				}
				if (this.form.second_id > 0) {
					for (let i = 0; i < this.secondList.length; i++) {
						if (this.secondList[i]['unit_id'] == this.form.second_id) {
							this.thirdList = this.secondList[i].child;
						}
					}
				}
				// 显示弹出层
				this.dialogVisible = true
			},
			allocationUser(item) {
				this.allocationUserVisible = true;
				this.allocationUserForm.report_id = item.report_id;
				this.allocationUserForm.unit_user_id = 0;
				this.allocationUserForm.real_name = '';
			},
			// 提交
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.layout.showLoading()
						this.axios({
							token: true,
							params: {
								s: 'store/report/edit',
								report_id: this.form.report_id
							},
							data: this.form,
							method: 'post',
						}).then(res => {
							if (res.data.code == 1) {
								this.$message({
									message: res.data.msg,
									type: 'success'
								});
								this.getList();
							} else {
								this.$message({
									message: res.data.msg,
									type: 'error'
								});
							}
							this.dialogVisible = false;
							this.layout.hideLoading()
						}).catch(err => {
							this.layout.hideLoading()
						})
					}
				})
			},
			submitAllocationUserForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.layout.showLoading()
						this.axios({
							token: true,
							params: {
								s: 'store/report/allocationUser',
								report_id: this.allocationUserForm.report_id
							},
							data: this.allocationUserForm,
							method: 'post',
						}).then(res => {
							if (res.data.code == 1) {
								this.$message({
									message: res.data.msg,
									type: 'success'
								});
								this.getList();
							} else {
								this.$message({
									message: res.data.msg,
									type: 'error'
								});
							}
							this.allocationUserVisible = false;
							this.layout.hideLoading()
						}).catch(err => {
							this.layout.hideLoading()
						})
					}
				})
			},
			toSearch() {
				this.search.page = 1;
				this.getList()
			},
			// 分页
			pageChange(page) {
				this.search.page = page;
				this.getList();
			},
			getList() {
				this.layout.showLoading()
				this.axios({
					token: true,
					params: this.search,
					method: 'get',
				}).then(res => {
					this.layout.hideLoading()
					this.lists = res.data.data
				}).catch(err => {
					this.layout.hideLoading()
				})
			},
			getUnitList() {
				this.axios({
					token: true,
					params: {
						s: 'store/unit/index',
					},
					method: 'get',
				}).then(res => {
					this.firstList = res.data.data

				}).catch(err => {});
			},
			// 删除单个
			deleteItem(data) {
				this.$confirm('是否要删除该举报吗?', '提示', {
					confirmButtonText: '删除',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.layout.showLoading()
					this.axios({
						token: true,
						params: {
							s: 'store/report/delete',
							report_id: data.report_id
						},
						method: 'get',
					}).then(res => {
						if (res.data.code == 1) {
							this.$message({
								message: res.data.msg,
								type: 'success'
							});
							this.getList()
						}
						this.layout.hideLoading()
					}).catch(err => {
						this.layout.hideLoading()
					})
				})
			},
		},
	}
</script>

<style>

</style>
